import {
  Pathnames,
  createLocalizedPathnamesNavigation,
} from "next-intl/navigation";

export const locales = ["es", "en"];
export const localePrefix = "as-needed";
export const pathnames = {
  "/": "/",
  "/blog": "/blog",
  "/nosotros": {
    es: "/nosotros",
    en: "/about",
  },
  "/proyectos": {
    es: "/proyectos",
    en: "/projects",
  },
  "/servicios": {
    es: "/servicios",
    en: "/services",
  },
  "/trabaja-con-nosotros": {
    es: "/trabaja-con-nosotros",
    en: "/join-us",
  },
  "/terminos-y-condiciones": {
    es: "/terminos-y-condiciones",
    en: "/terms-and-conditions",
  },
  "/politicas-de-privacidad": {
    es: "/politicas-de-privacidad",
    en: "/privacy-policy",
  },
  "/politicas-de-calidad": {
    es: "/politicas-de-calidad",
    en: "/quality-policy",
  },
  "/proyectos/[project]": {
    es: "/proyectos/[project]",
    en: "/projects/[project]",
  },
  "/servicios/[slug]": {
    es: "/servicios/[slug]",
    en: "/services/[slug]",
  },
  "/contacto": {
    es: "/contacto",
    en: "/contact",
  },
  // Otros pathnames localizados que necesites
} satisfies Pathnames<typeof locales>;

export const { Link, redirect, usePathname, useRouter, getPathname } =
  createLocalizedPathnamesNavigation({ locales, localePrefix, pathnames });
